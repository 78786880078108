@import "common/variables";
@import "components/bootstrap/bootstrap-grid";

footer.content-info {
	font-size: 14px;
	@include media-breakpoint-up(md) {
		font-size: 16px;
	}
	color: white;
	padding: 43px 0 58px;
	background-color: $gray;

	.textwidget a {
		color: $pink;
		&:before {
			margin-right: .25rem;
			content: "\f105";
			display: inline-block;
			font: normal normal normal 14px/1 FontAwesome;
			font-size: inherit;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
	}


	body:not(.home) & {
		margin-top: 60px;
	}
}
